@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@1,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@700&display=swap');


$text-color: #e990e2;
$shadow-color: mix(#fff, $text-color, 30%);

$caret-color: #166f7a8e;

$background-color: #181818;
$medium: 750px;

.App-container {
  background-color: $background-color;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  font-size: calc(10px + 2vmin);
  font-family: 'Fira Sans';
  color: whitesmoke;

  background-image:  radial-gradient(#e990e2 1px, transparent 1px), radial-gradient(#e990e2 1px, #181818 1px);
  background-size: 40px 40px;
  background-position: 0 0,20px 20px;

  overflow: hidden;
  
}

.child {
  height: 100vh;
  width: 100%;
}

.App-header {
  
  margin-top: 20vh;
  
  @media (max-width: $medium) {
    margin-left: 5vw;
  }

  @media screen and (min-width: $medium) {
    margin-left: 10vw;
  }
  background-color: $background-color;

  width: max-content;
  max-width: 75vw;

  padding: 25px;
  border-radius: 25px;
  border: white solid 1px;
}

.App-name {
  text-align: left;
  font-size: 60px;
  font-weight: 900;
  margin-bottom: 15px;

  word-spacing: -20px;
}



h1 {
 
  color: $text-color;


  @media (max-width: $medium) {
    font-size: 1em;
  }

  @media screen and (min-width: $medium) {
    font-size: calc(1em + 2vw) ;  
  }
	
  
	font-weight: 900;

  text-shadow: -0.0075em 0.0075em 0 $shadow-color,
  0.005em 0.005em 0 $shadow-color,
  0.01em 0.01em 0 $shadow-color,
  0.015em 0.015em $shadow-color,
  0.02em 0.02em 0 $shadow-color,
  0.025em 0.025em 0 $shadow-color,
  0.03em 0.03em 0 $shadow-color,
  0.035em 0.035em 0 $shadow-color,
  0.04em 0.04em 0 $shadow-color,
  0.045em 0.045em 0 $shadow-color,
  0.05em 0.05em 0 $shadow-color;

  margin: 0px;
  padding-bottom: 5px;
  padding-right: 10px;

  font-family: 'Fira Mono', monospace;
}



@keyframes blink-caret {
  from, to { border-color: transparent} 
  50% {border-color: $caret-color}
}

.App-bio {
  display: flex;
  flex-direction: row;
  

  // width: 100%;
  text-align: left;
  font-size: 20px;
  font-weight: 900;
  
}

.App-bio div {
  margin-left: 8px;
}

.App-bio #first {
  text-decoration: underline #F0803C;
}

.App-bio #second {
  text-decoration: underline #E4C1F9;
}

.App-bio #third {
  text-decoration: underline #EDF060;
}

.App-location {
  display: flex;
  flex-direction: row;
  margin-left: 3px;
}

.App-location p {
  padding-left: 5px;
  color: whitesmoke;
  font-style: italic;
  font-size: 18px;
}

.App-socials {
  text-align: left;
  font-size: 18px;
  font-weight: 100;
}

.App-socials a {
  color: #EFF7F6;
  font-style: italic;
  font-weight: 900;
  @media (max-width: $medium) {
    font-size: 0.8em;
  }

  // @media screen and (min-width: $medium) {
  //   font-size: calc(1em + 2vw) ;  
  // }
}

.App-nextpage button {
  background: none;
  border: none;
  color: white;
  font-size: 40px;
  padding-bottom: 10px;

  cursor: pointer  ;
}

.Bio-container {
  background-color: $background-color;

  height: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  
  font-size: calc(10px + 2vmin);
  font-family: 'Fira Sans';
  color: whitesmoke; 
}